/* Custom Scripts Below */

/* Settings Page */
.wizard-step {
  cursor: pointer;
}
.btn-gpay {
  background: #000;
}
.btn-gpay svg {
  max-height: 20px;
}
.kt-menu__nav li {
  list-style-type: none;
  margin-top: 20px;
}

.shift-over {
  background: #fafafa;
}
#segmented-tabs {
  border: 1px solid #eee;
  border-radius: 50px;
}
#segmented-tabs li {
  width: 50%;
  margin: 0;
  border-radius: 50px;
}
#segmented-tabs li a {
  border-radius: 50px;
}
#segmented-tabs li a.active {
  background-color: #9600ff;
}
#segmented-tabs li a.active .nav-text,
#segmented-tabs li a.active .nav-icon .svg-icon svg g [fill] {
  color: #fff;
  fill: #fff;
}

.nav.nav-pills .nav-link .nav-text {
  font-weight: 900;
  font-size: 18px;
}

.input-group-payment-form {
  border-right: none;
  background: #fff;
}
.tipping-form {
  background: #fff;
}

.nav-link.active:not(.quick-menu),
.pay-btn {
  background: rgb(143, 33, 212);
  background: linear-gradient(
    0deg,
    rgba(143, 33, 212, 1) 0%,
    rgba(40, 26, 200, 1) 100%
  );
}

.price-button a {
  border-color: #771ba4 !important;
}
.price-button h2 {
  color: #771ba4;
}

.bg-secondary {
  background-color: #3200ff !important;
}

.bg-tertiary {
  background-color: #69f0ae;
}

@media (min-width: 1200px) {
  .one-fifth {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 12.5px;
    padding-left: 12.5px;
  }
}

@media (max-width: 600px) {
  .flex-column {
    flex-direction: inherit !important;
  }
  .price-button {
    width: 30%;
    display: inline-block !important;
  }
  .custom-amount {
    height: 46px;
  }
}

/* Margin & Padding */
.side-margin-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.kt-aside--minimize .side-margin-20 {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-l-50 {
  margin-left: 50px !important;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-t-0 {
  padding-top: 0px;
}

/* Stat Boxes */
.kt-widget24 {
  text-align: center;
  margin: 30px 0px;
}
.kt-widget24__info {
  width: 100%;
}
.kt-widget24__title {
  display: block !important;
}
.kt-widget24 .kt-widget24__details .kt-widget24__stats {
  font-size: 40px;
}

/* Add Page Card */
.add-page-portlet {
  box-shadow: none;
  border: 10px dashed #ddd;
  background: none;
}
.add-page-portlet a.page-name {
  color: #bbb !important;
}
.add-page-two {
  box-shadow: none;
  border: none;
  background: #d8dbe8;
}
.add-page-two a.page-name {
  color: #fff !important;
}
@media (min-width: 1200px) {
  .add-page-portlet .kt-widget__head,
  .add-page-two .kt-widget__head {
    margin-top: 50px !important;
  }
}

/* Page Card */
.kt-widget__stats {
  padding: 1.7rem 1.5rem 0;
  font-size: 13px;
}
.kt-widget__stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.kt-widget__stat .kt-widget__label {
  color: #48465b;
  font-weight: 600;
}
.kt-widget__stat .kt-widget__data {
  color: #74788d;
  font-weight: 400;
}

/* Inputs */
.keywords-lg {
  padding: 0.65rem 1.65rem;
  font-size: 1.25rem;
}
.kt-widget.kt-widget--user-profile-4
  .kt-widget__head
  .kt-widget__content
  .kt-widget__section
  .page-name {
  font-size: 1.9rem;
}
.kt-badge.kt-badge--warning {
  color: #fff;
}

/* Start TABLE Styles */
.ReactTable {
  border: unset;
}

.Table__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Table__itemCount {
  /* margin-top: 10px; */
  font-size: 14px;
}

.Table__pagination {
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
}

.Table__page {
  display: flex;
  justify-content: flex-end;
  padding: 25px 15px;
}

.Table__visiblePagesWrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.Table__pageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: transparent;
  color: #93a2dd;
  cursor: pointer;
}

.Table__pageButton--active,
.Table__pageButton:hover {
  color: #fff;
  font-weight: bold;
  -webkit-transition: background-color 3.3s ease;
  background-color: #074785;
}

.table-status {
  border: 1px solid #e2e5ec !important;
  height: 38px !important;
}

.ReactTable .rt-table {
  overflow: unset;
  overflow-y: visible !important;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

.switchPageButton {
  font-size: 15px;
  outline: none;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  height: 29px;
  width: 29px;
  border-radius: 5px;
  background-color: #f0f3ff;
  color: #93a2dd;
  cursor: pointer;
}

.switchPageButton:disabled {
  cursor: not-allowed;
  color: #e0e5f5;
  background-color: #fbfcff;
}

.switchPageButton:disabled:hover {
  cursor: not-allowed;
  color: #f1f4fc;
  font-weight: normal;
  background-color: #fbfcff;
}

.switchPageButton:active,
.switchPageButton:hover {
  color: #fff;
  background-color: #074785;
}

.select-page-text {
  color: #05223d;
  font-size: 13px;
  margin-left: 15px;
}

.select-page {
  border-width: 0;
  color: #93a2dd;
  font-size: 13px;
  font-weight: 600;
  height: 29px;
  padding-left: 10px;
  width: 60px;
  border-radius: 5px;
  background: url(../../assets/media/icons/chevron-down.svg) no-repeat right
    #f0f3ff;
  -webkit-appearance: none;
}

.select-page option {
  font-size: 13px;
  font-weight: bold;
}

.select-page:hover {
  border-width: 0;
  color: #fff;
  background-color: #074785;
}

.select-page:active {
  border-width: 0;
  color: #fff;
  background-color: #074785;
  -webkit-appearance: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #af52d9;
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::before {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::after {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
}
.-sort-asc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  opacity: 0.3;
}
.-sort-desc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.-sort-desc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #af52d9;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}
/* End TABLE Styles */

.rdw-editor-main {
  padding: 1.15rem 1.65rem;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-color: #e2e5ec;
  border-style: solid;
}

.text-editor-wrapper {
  border: 1px solid #e2e5ec;
}

.rdw-editor-toolbar {
  border-style: none;
}

.select-status-filter {
  width: 300px;
}

.error-message {
  color: #fd397a;
}

.stat_chart canvas {
  height: 100px !important;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

span.input-group-addon {
  display: inline-flex;
  align-items: center;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.metr_v702 .rounded-lg.login-slug-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group-append-bg-dark span {
  background: #333;
  width: 180px;
  border: 0;
  color: white;
  font-weight: 600;
}

.input-group-append-bg-dark-error span {
  background: #fa6160;
  color: white;
}

.input-group-append-bg-green span {
  background: #37db70;
  color: white;
}

.kt-wizard-v2__nav-item {
  cursor: pointer;
}

.modal-50vw {
  width: 50vw !important;
  max-width: 50vw !important;
}

.modal-60vw {
  width: 60vw;
  max-width: 60vw;
}

.modal-70vw {
  width: 70vw;
  max-width: 70vw;
  margin: 1.75rem auto;
}
@media (max-width: 576px) {
  .modal-70vw {
    width: 90vw;
    max-width: 90vw;
    margin: 1.75rem auto;
  }
}

.reorderable-image {
  cursor: pointer;
}

.modal .reorderable-image {
  z-index: 9999999 !important;
  cursor: pointer;
}

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins, Helvetica, sans-serif;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.disable-view .logo-svg {
  width: 240px;
  height: 240px;
  top: 31px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.disable-view-absolute {
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
}

.activate-shop-banner {
  background-color: #333;
  color: white;
  padding: 20px;
}

.google-suggestion-item {
  cursor: pointer;
  padding: 5px;
  padding-left: 8px;
  font-size: 12px;
  margin: 0px;
}

.google-suggestion-item:hover {
  background-color: #f5f5f5;
}

.google-suggestion-list {
  box-shadow: 0 0.4rem 0.5rem 0.0625rem #dbdbdc !important;
}

.google-suggestion-item-manual {
  color: #fa6160;
  text-decoration: underline;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

p.shop-charges-disabled {
  color: #fa1f60;
}

p.shop-charges-enabled {
  color: #faaa1f;
}

.form-control.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

.input-group.input-group-solid,
.async-select-paginate__control {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f6f9 !important;
  border-radius: 0.42rem;
}

.async-select-paginate-filter__control {
  border-color: #e2e5ec !important;
}

.async-select-paginate:first-child,
.async-select-paginate > div:first-child {
  background-color: #f3f6f9 !important;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.react-tel-input .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem 0.65rem 48px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
}

.chip-container > div,
.chip-container input {
  background-color: #f3f6f9 !important;
  border: none !important;
}

.async-select-paginate div {
  border: 0;
}

.separator.separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}
.separator.separator-custom {
  border-bottom-color: #f3f6f9;
}
.separator.separator-border-2 {
  border-bottom-width: 2px;
}
.separator.separator-border-3 {
  border-bottom-width: 3px;
}
.separator.separator-border-4 {
  border-bottom-width: 4px;
}

.separator {
  height: 0;
  border-bottom: solid;
}

.Collapsible .collapse .svg-icon svg {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.Collapsible .svg-icon svg {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: transparent;
}

.flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important;
}

.async-select-paginate__control {
  background-color: red;
}

.w-50 {
  width: 50vw;
  max-width: 50vw;
}

.form-control:disabled {
  background-color: #f1f1f1;
  opacity: 1;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-indeterminate {
  background-color: #f7ebff !important;
}
.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-barColorPrimary,
.MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
  background-color: #00ffb1 !important;
}

.Toastify__toast--success {
  background-color: #37db70;
  text-align: center;
}

.Toastify__toast--error {
  background-color: #fa6160;
  text-align: center;
}

.Toastify__toast-body {
  font-weight: 500;
  font-size: 16px;
}

.btn.btn-light {
  color: #80808f;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #3f494d !important;
  border-color: #3f494d !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-hover-primary:focus:not(.btn-text) i,
.btn.btn-hover-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.Collapsible.open .Collapsible__contentOuter {
  overflow: visible !important;
}

.product-categories {
  padding-top: calc(0.65rem + 1px) !important;
}

.alert.alert-custom.alert-white .alert-text {
  color: #3f4254;
}

.alert.alert-custom.alert-white .alert-icon i {
  opacity: 0.8;
  color: #3f4254;
}

.alert.alert-custom.alert-warning .alert-text {
  color: #ffffff;
}

.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}

.alert.alert-custom.alert-dark {
  background-color: #181c32;
  border-color: #181c32;
}

.ki-close:before {
  content: "\f130";
}

.ki:before {
  font-family: "Ki";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

/* starts : custom base table css */
.c-base-table.scroll-fixed {
  padding-top: 88px;
}
.c-base-table.scroll-fixed .action-bar,
.c-base-table.scroll-fixed .rt-thead,
.c-base-table.scroll-fixed::before {
  position: fixed;
  top: 90px;
  background-color: #fff;
  left: 315px;
  right: 45px;
  z-index: 1;
}
.c-base-table.scroll-fixed .rt-thead {
  top: 128px;
}
.c-base-table.scroll-fixed::before {
  content: " ";
  z-index: 2;
  border-top: 25px solid white;
  display: block;
  top: 65px;
}
/* end : custom base table css */

/* starts : custom react-switch css */
.c-switch {
  top: 2.5px;
}
/* end : custom react-switch css */

.modal-70 {
  width: 70% !important;
  max-width: 70% !important;
}

@media (max-width: 576px) {
  .modal-70 {
    width: 90% !important;
    max-width: 90% !important;
    margin: 1.75rem auto;
  }
}

.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}

/* starts : custom checkbox css */
.kt-checkbox.c-checkbox {
  padding-left: 45px;
  padding-top: 5px;
}
.kt-checkbox.c-checkbox > span {
  height: 28px;
  width: 28px;
  left: 5px;
  border-radius: 0.25rem;
}
.kt-checkbox.c-checkbox > span {
  border-color: #af52d9;
}
.kt-checkbox.c-checkbox > input:checked ~ span {
  background-color: #af52d9;
}
.kt-checkbox.c-checkbox > input:checked ~ span:after {
  border-color: #fff;
}
/* end : custom checkbox css */

.cursor-pointer {
  cursor: pointer;
}

.form-control-error {
  border-color: #fa1f60 !important;
}

.choose-image {
  position: relative;
  z-index: 0;
}
.choose-image::after {
  background-image: url(../media/bg/step-02.png);
  content: " ";
  z-index: 1;
}
.choose-image .bg-text {
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.choose-image::after,
.choose-image .uploaded-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.choose-image .uploaded-image {
  z-index: 3;
}
.choose-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

/* starts, login-1 page fixed login-aside */
.login-1 {
}
@media (min-width: 992px) {
  .login-1 .login-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1025px) {
  .login-1 {
    padding-left: 600px;
  }
}
@media (max-width: 1399.98px) and (min-width: 992px) {
  .login-1 {
    padding-left: 450px;
  }
}
/* end, login-1 page fixed login-aside */

/* starts, color-picker */
.color-picker .color {
  position: relative;
  z-index: 0;
  display: inline-block;
  margin-top: 6px;
}
.color-picker .color-box-value {
  margin-top: 6px;
}
.color-picker .color div.selected::before {
  position: absolute;
  top: 13.5px;
  left: 13px;
  color: white;
  font-size: 1.5em;
}
/* starts, color-picker */

.subscription-discount {
  color: #37db70;
  font-weight: 600;
}

.article-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.cropper-modal {
  background-color: #f1f1f1;
}

.kt-avatar__holder.bg-pos {
  background-position: 50% 50% !important;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.shine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.p-7 {
  padding: 1.75rem !important;
}

@media (min-width: 768px) {
  .pt-38,
  .py-38 {
    padding-top: 7rem !important;
  }
  .pb-38,
  .py-38 {
    padding-bottom: 7rem !important;
  }
}

.mb-8,
.my-8 {
  margin-bottom: 2rem !important;
}
.mt-8,
.my-8 {
  margin-top: 2rem !important;
}

.c-separator hr {
  border-top: 3px dashed rgba(0, 0, 0, 0.1);
}

.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822;
}

.website-selector {
  width: 175px;
}

.website-selector-wide {
  width: 300px;
}

.website-selector .async-select-paginate__input {
  display: none !important;
}

.website-selector .async-select-paginate__value-container {
  max-height: 25px;
}

/* starts, aside menu */
@media (min-width: 1025px) {
  .kt-aside--fixed .kt-aside {
    overflow: auto;
  }
}
/* end, aside menu */

/* starts, board */

.board-container {
  display: block;
}

.board {
  display: block;
  overflow: auto;
}

.columns-container {
  display: flex;
  align-items: start;
  overflow-x: auto;
  height: calc(100vh - 14rem);
}

.column-container {
  margin: 8px;
  border-radius: 10px;
  min-width: 250px;
  max-height: calc(100vh - 15rem);
  display: flex;
  background-color: #dcdbe0;
  flex-direction: column;
}

.column-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #333;
  padding: 1rem;
}

.column-badge {
  display: flex;
  height: 30px;
  width: 30px;
  background-color: black;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 15px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.card-list {
  padding: 8px 0 8px 8px;
  border-radius: inherit;
  transition: background-color 0.2s ease;
  min-height: 10px;
  background-color: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.card-list-y-auto {
  overflow-y: auto;
  padding: 0 8px 0 0;
}

.card-list-y-auto::-webkit-scrollbar {
  width: 10px;
  height: 18px;
}
.card-list-y-auto::-webkit-scrollbar {
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
}

.card-list-is-dragging {
  padding: 8px;
  background-color: inherit;
  border-radius: inherit;
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 20px;
}

.card-container {
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: white;
}

.card-dragging-container {
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: white;
}

/* end, board */
.ReactTable .rt-table {
  overflow: unset;
  overflow-y: visible !important;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

.rdw-list-dropdown,
.rdw-text-align-dropdown {
  z-index: unset;
}
.ReactTable .rt-expander:after {
  border-top-color: #9600ff;
}

/*** QR Codes Table ***/
.print-btn {
  margin-left: 3rem;
  width: 100px;
}

.generate-btn {
  width: 200px !important;
}

.sub-modal {
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.15s linear;
}
