@media (min-width: 1025px) {
  .kt-aside__brand {
    background-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .kt-aside__brand .kt-aside__brand-tools svg g [fill] {
      fill: #af52d9; } }

@media (max-width: 1024px) {
  .kt-header-mobile {
    background-color: #ffffff;
    border-bottom: 1px solid #eff0f6; }
    .kt-header-mobile--fixed.kt-header--minimize .kt-header-mobile {
      border-bottom: 0;
      -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1); }
    .kt-header__topbar--mobile-on .kt-header-mobile {
      -webkit-box-shadow: none;
      box-shadow: none; }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span {
      background: #b4b8ce; }
      .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span::after {
        background: #b4b8ce; }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span {
      background: #af52d9; }
      .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler:hover span::after {
        background: #af52d9; }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span {
      background: #af52d9; }
      .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::before, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--active span::after {
        background: #af52d9; }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler i {
      color: #b4b8ce; }
    .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active i, .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i {
      color: #af52d9; } }
